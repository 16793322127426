import React from "react";
import Layout from "@/components/Layout";
import Breadcrumb from "@/components/Common/Breadcrumb";
import PrivacyPolicyPage from "@/components/PrivacyPolicy";
import SEO from "@/components/seo";
import { useLocation } from "@reach/router";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Privacy Policy" pathName="Privacy Policy" />
      <PrivacyPolicyPage />
    </Layout>
  );
};

export default PrivacyPolicy;
